import React from "react";

// components
import { CgMenuLeftAlt, CgMenuRight } from "react-icons/cg";

// react router navigation
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../store";
import { get_profile } from "../../../services/apis/setting";

type INavbar = {
  collapse: boolean;
  onCollapse: () => void;
};

const Navbar: React.FC<INavbar> = ({ collapse, onCollapse }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const profile = useAppSelector(({ setting }) => setting.profile);

  const onLoad = React.useCallback(() => {
    get_profile(dispatch);
  }, [dispatch]);

  React.useEffect(() => {
    onLoad();
  }, [onLoad]);

  return (
    <header className="w-full flex justify-between items-center px-2  text-gray">
      <div className="w-full flex justify-between items-center h-[3rem]">
        <div className="cursor-pointer">
          {collapse ? (
            <CgMenuLeftAlt size={30} onClick={onCollapse} />
          ) : (
            <CgMenuRight size={30} onClick={onCollapse} />
          )}
        </div>

        <div className="flex items-center">
          <img
            src={profile?.profile?.avatar}
            alt={profile?.profile?.name}
            className="w-[2.5rem] h-[2.5rem] rounded-full mr-3 cursor-pointer"
            onClick={() => navigate("/profile")}
          />

          <span className="cursor-pointer">Logout</span>
        </div>
      </div>
    </header>
  );
};

export default Navbar;
