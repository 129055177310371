import React from "react";

// components
import SideNav from "./components/SideNav";

// new
import Navbar from "./components/Navbar";

type ITheme = {
  children: React.ReactNode;
};

const Theme: React.FC<ITheme> = ({ children }) => {
  const [collapsed, setCollapsed] = React.useState<boolean>(false);

  return (
    <main className="w-[100vw] h-[100vh] overflow-hidden gap-x-1 grid grid-cols-[250px,1fr] grid-rows-1">
      <SideNav collapse={collapsed} />
      <main className="grid grid-cols-1 grid-rows-[10vh,89vh] gap-y-1 bg-white">
        <Navbar
          collapse={collapsed}
          onCollapse={() => setCollapsed(!collapsed)}
        />

        <main className="overflow-x-hidden overflow-y-auto p-3 shadow-md">
          {children}
        </main>
      </main>
    </main>
  );
};

export default Theme;
