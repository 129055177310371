import React from "react";

// components
import { FaBuilding, FaFlag, FaBlog, FaRunning, FaCogs } from "react-icons/fa";
import { HiLightBulb } from "react-icons/hi";
import { RiCustomerService2Fill } from "react-icons/ri";
import { MdDashboard } from "react-icons/md";

// assets and constants
import logo from "../../../assets/images/logo/logo.jpeg";
import { NavLink, useNavigate } from "react-router-dom";

type ItemType = {
  key: number;
  link: string;
  title: string;
  icon?: React.ReactNode;
}[];

type ISideNav = {
  collapse: boolean;
};

const items: ItemType = [
  {
    key: 1,
    icon: <MdDashboard size={25} />,
    link: "/",
    title: "Dashboard",
  },
  {
    key: 2,
    icon: <HiLightBulb size={25} />,
    link: "/opportunity",
    title: "Opportunity",
  },
  {
    key: 3,
    icon: <FaRunning size={25} />,
    link: "/trainings",
    title: "Training",
  },
  {
    key: 4,
    icon: <FaBlog size={25} />,
    link: "/blogs",
    title: "Blog",
  },
  {
    key: 5,
    icon: <FaBuilding size={25} />,
    link: "/companies",
    title: "Company",
  },
  {
    key: 6,
    icon: <FaCogs size={25} />,
    link: "/services",
    title: "Services",
  },
  {
    key: 7,
    icon: <RiCustomerService2Fill size={25} />,
    link: "/requests",
    title: "Service Request",
  },
  {
    key: 8,
    icon: <FaFlag size={25} />,
    link: "/countries",
    title: "Countries",
  },
];

const SideNav: React.FC<ISideNav> = () => {
  const navigate = useNavigate();

  return (
    <nav className="bg-primary-500 overflow-hidden h-[100vh]">
      <div className="w-full flex items-center justify-center h-[100px]">
        <img
          src={logo}
          alt="logo"
          className="w-[50px] h-[50px] cursor-pointer"
          onClick={() => navigate("/")}
        />
      </div>

      <nav className="grid gap-3 px-4 overflow-hidden">
        {items.map((item) => (
          <NavLink
            key={item.key}
            className={({ isPending, isActive }) =>
              `text-[1.2rem] text-[white] ${isActive || isPending ? "bg-primary-600" : ""}`
            }
            to={item.link}
          >
            <div className="flex items-center gap-3 w-full">
              {item?.icon} {item?.title}
            </div>
          </NavLink>
        ))}
      </nav>
    </nav>
  );
};

export default SideNav;
